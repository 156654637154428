import { AnalyticsBrowser, AnalyticsSnippet } from "@segment/analytics-next";
import * as snippet from '@segment/snippet'

export const segmentUserAuthenticatedAnalytics = new AnalyticsBrowser();

segmentUserAuthenticatedAnalytics.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY as string,
});

// For unauthenticated events
export const segmentUserUnauthenticatedAnalytics = (): AnalyticsSnippet | undefined => {
  return typeof window !== "undefined" ? window.analytics : undefined;
};

export const renderScript = () => snippet.min({
  host: 'cdn.segment.com',
  apiKey: process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY,
  page: false
});
