import { gql } from "@apollo/client";
import { initializeApollo } from "../lib/apollo/apollo-client";
import { S2ItemDescriptionT } from "../utils/contentfulTypes";

const ITEM_DESCRIPTION = gql`
  query S2ItemDescription($id: String!) {
    s2ItemDescription(id: $id) {
      title
      description
      descriptionRichText {
        json
      }
      sectionTitle
      sectionText
      listTitle
      list
      calloutTitle
      calloutText {
        json
      }
      carouselImagesCollection {
        items {
          url
        }
      }
      carouselImagesMobileCollection {
        items {
          url
        }
      }
    }
  }
`;

const context = { clientName: "contentful" };

export const getS2ItemDescription = async (
  id: string
): Promise<S2ItemDescriptionT> => {
  const apolloClient = initializeApollo();
  const { data } = await apolloClient.query({
    query: ITEM_DESCRIPTION,
    variables: { id },
    context,
  });

  return data.s2ItemDescription;
};

const GET_FAQ = gql`
  query Repeater {
    repeater(id: "677nZNvzaONBfdIGN5224p") {
      title
      itemCollection {
        items {
          ... on Text {
            headline
            text
            richText {
              json
            }
          }
        }
      }
      data
    }
  }
`;

export const getFAQ = async () => {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data } = await apolloClient.query({
    query: GET_FAQ,
    context,
  });

  return data.repeater.itemCollection.items;
};

const GET_TEXT = gql`
  query Text($id: String!) {
    text(id: $id) {
      headline
      subheadline
      text
    }
  }
`;

export const getText = async (id: string) => {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data } = await apolloClient.query({
    query: GET_TEXT,
    variables: { id },
    context,
  });

  console.log(data);

  return data.text;
};

const GET_MEDIA = gql`
  query Media ($id: String!) {
    media(id: $id) {
      title
      headline
      video
      videoMobile
      imageMobile {
        url
      }
      imageDesktop {
        url
      }
    }
  }
`;

export const getMedia = async (id: string) => {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data } = await apolloClient.query({
    query: GET_MEDIA,
    variables: { id },
    context,
  });

  return data.media;
}

const GET_CARD = gql`
  query Card($id: String!) {
    card(id: $id) {
      headline
      subheadline
      text
      buttonText
      buttonLink
      image {
        url
      }
      imageMobile {
        url
      }
    }
  }
`;

export const getCard = async (id: string) => {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data } = await apolloClient.query({
    query: GET_CARD,
    variables: { id },
    context,
  });

  return data.card;
}

const GET_GALLERY = gql`
  query Gallery($id: String!) {
    gallery(id: $id) {
      headline
      imagesCollection {
        items {
          url
        }
      }
    }
  }
`;

export const getGallery = async (id: string) => {
  const apolloClient = initializeApollo();
  const context = { clientName: "contentful" };

  const { data } = await apolloClient.query({
    query: GET_GALLERY,
    variables: { id },
    context,
  });

  return data.gallery;
}