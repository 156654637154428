import { getS2ItemDescription } from "@/queries/contentfulQueries";
import React from "react";

const AppBanner = () => {
  const getBannerDescription = async () => {
    const data = await getS2ItemDescription("2UU0p3SxX4nov33FhoeTSJ");
    return data?.description;
  };

  const [bannerText, setBannerText] = React.useState("");

  React.useEffect(() => {
    const fetchBanner = async () => {
      const text = await getBannerDescription();
      setBannerText(text || "");
    };
    fetchBanner();
  }, []);

  if (!bannerText) return null;

  return (
    <div className="bg-alter-black-80 text-white h-[33px] w-full flex items-center justify-center">
      <p className="text-sm">{bannerText}</p>
    </div>
  );
};

export default AppBanner;
